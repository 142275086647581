import React from "react";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import { useSelector } from "react-redux";
import { IIPedsReportParamsFallByLevel } from "../../../interfaces/reports/IPedsReportParamsFallByLevel";
import { FormContext, useForm } from "react-hook-form";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import ProgramAutoComplete from "../../../components/AutoComplete/ProgramAutoComplete";
import { Autocomplete } from "@material-ui/lab";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { getCostIIFinancialAidPartA } from "../../../api/reports/ipeds/IPEDSFall";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FieldError } from "react-hook-form";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import ProgramsAutoComplete from '../../../components/AutoComplete/ProgramsAutoComplete';


const getReportingYearOptions = (
  yearMod: number = 1,
  yearRange: number = 1
): Array<string> => {
  let years: Array<string> = [];
  const currentYear = new Date().getFullYear();
  for (let i = yearMod; i > -9; i--) {
    const start = (currentYear + i - yearRange).toString();
    const end = (currentYear + i).toString();
    years.push(start + "-" + end);
  }
  return years;
};

const getDatesText = (reportingYear: string): string => {
  const [start, end] = reportingYear.split("-");
  return (
    new Date(Number(start) - 1, 6, 1).toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }) +
    " thru " +
    new Date(Number(end) - 1, 5, 30).toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })
  );
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    switch: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(8.6),
    },
    errorText: {
      color: "red",
    },
    startDateField: {
      width: "100%",
    },
    truncateLabel: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "inlineBlock",
      maxWidth: "100%",
    },
  })
);

const CostIIFinancialAidPartA: React.FC<{ report: any }> = ({
  report,
}) => {
  const classes = useStyles({});

  const [loading, setLoading] = React.useState<boolean>(false);
  const [previewData, setPreviewData] = React.useState<any>(undefined);

  const reportOverview = { report: report } as IReportOverview;
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const data = useForm<any>({ mode: "onBlur" });

  const [model, setModel] = React.useState<IIPedsReportParamsFallByLevel>({
    campusId: userSelectedCampus,
    reportingYear: getReportingYearOptions()[0],
    orderBy: "StudentNumber",
    showInactive: false,
    schoolReportingType: "ProgramReporter",
    schoolType: "COPCPL",
  });

  const handleFieldOnChange = (
    fieldId: keyof IIPedsReportParamsFallByLevel,
    value: any,
    mapFunction?: Function
  ) => {
    const updatedModel = {
      ...model,
      [fieldId]: mapFunction ? mapFunction(value) : value,
    };
    if (fieldId === "schoolReportingType") {
      updatedModel.schoolType =
        value === "ProgramReporter" ? undefined : model.schoolType;
    }
    setModel(updatedModel);
  };

  const handleCampusChange = (value: any) => {
    let updatedModel = model;
    updatedModel.campusId = value;
    setModel({
      ...updatedModel,
      progId: undefined,
      largestProgramID: undefined,
    });
  };

  const { handleSubmit } = data;

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel({
        campusId: userSelectedCampus,
        reportingYear: getReportingYearOptions()[0],
        orderBy: "StudentNumber",
        showInactive: false,
        schoolReportingType: "ProgramReporter",
        startDate: undefined as Date | undefined,
        schoolType: "COECPL",
      });
    }
  };

  const [reportData2, setReportData2] = React.useState(null);

  const onSubmit = async (isPreview: boolean) => {
    let params = (await getReportParameters()) as IIPedsReportParamsFallByLevel;
    const validation = await data.triggerValidation();

    if (validation) {
      if (isPreview) {
        setLoading(true);
      }

      // const reportData = await getFallAllCompletions_ByLevel(params, isPreview);

      try {
        const reportData = await getCostIIFinancialAidPartA(params, isPreview);
        //setReportData2(reportData);

        if (isPreview) {
          if (reportData) {
            setPreviewData("data:application/pdf;base64," + reportData);
          }
        }
      } catch (error) {
        console.error("Error fetching report data:", error);

        //setReportData2(null);
      } finally {
        if (isPreview) {
          setLoading(false);
        }
      }
    }
  };

  React.useEffect(() => {
    if (reportData2 !== null || reportData2 !== undefined) {
      console.log("UpdatedBlah", reportData2);
    }
  }, [reportData2]);

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let params: IIPedsReportParamsFallByLevel = {
        campusId: model.campusId,
        progId: model.progId,
        orderBy: model.orderBy,
        showInactive: model.showInactive,
        reportingYear: model.reportingYear,
        largestProgramID: model.largestProgramID,
        schoolReportingType: model.schoolReportingType,
        startDate: model.startDate,
        schoolType: model.schoolType,
      };
      return params;
    }
    return {} as IIPedsReportParamsFallByLevel;
  };

  data.register(
    { name: "campusId" },
    {
      validate: () => {
        return model.campusId ? true : "Campus is required.";
      },
    }
  );

  data.register(
    { name: "reportingYear" },
    {
      validate: () => {
        return model.reportingYear ? true : "Reporting Year is required.";
      },
    }
  );

  data.register(
    { name: "orderBy" },
    {
      validate: () => {
        return model.orderBy ? true : "Sort By is required.";
      },
    }
  );

  data.register(
    { name: "schoolReportingType" },
    {
      validate: () => {
        return model.schoolReportingType ? true : "Reporting Type is required.";
      },
    }
  );

  const reportTypes: Array<DropDownListItem> = [
    { text: "Academic Reporters - Public", id: "AcademicReportersPublic" },
    { text: "Academic Reporters - Private", id: "AcademicReportersPrivate" },
    { text: "Program Reporters - Public", id: "ProgramReportersPublic" },
    { text: "Program Reporters - Private", id: "ProgramReportersPrivate" },
  ];

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) =>
    items.find((opt) => opt.id === value) || ({} as DropDownListItem);

  const reportingTypeA =
    model.schoolReportingType === "ProgramReporter"
      ? "ProgramReporter"
      : "AcademicYearReporter";

  const parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={data.handleSubmit(() => {})}>
          <Grid container spacing={1}>
            <Grid item md={4} sm={4} xs={12}>
              <CampusForCurrentUserAutoComplete
                name="campusId"
                valueFilter={
                  model.campusId
                    ? { key: "value", values: [model.campusId] }
                    : undefined
                }
                filterHandle={(v: any) =>
                  handleFieldOnChange("campusId", v ? v.value : undefined)
                }
                error={!!data.errors.campusId}
                helperText={
                  data.errors.campusId && data.errors.campusId.message
                }
                inputRef={data.register({ required: "Campus is required." })}
              />
            </Grid>
          </Grid>
          <Grid item md={9} sm={9} xs={12}>
            <ProgramAutoComplete
              chipSize="small"
              maxTags={1}
              multiple
              name="progId"
              valueFilter={
                model.progId
                  ? {
                      key: "value",
                      values: model.progId.split(","),
                    }
                  : undefined
              }
              filterHandle={(v: any) =>
                handleFieldOnChange("progId", v || [], (v: any[]) =>
                  v.reduce((a, b) => ((a && a + ",") || "") + b.value, "")
                )
              }
              activeOnly={model.showInactive ? false : true}
              label="Programs"
              campusIds={model.campusId ? [model.campusId] : []}
            />
          </Grid>         
<Grid item md={9} sm={9} xs={12}>
									<ProgramsAutoComplete
										id="toProgram"
										name="toProgram"
										label="Select largest program paying the lower of in-state or in-district tuition rate for the academic year"
										filterHandle={(v: any) => {
											handleFieldOnChange('largestProgramID', v ? v.value : undefined);
										}}
										valueFilter={
											model.largestProgramID
												? {
													key: 'value',
													values: [model.largestProgramID],
												}
												: undefined
										}
										activePrograms={true}
										error={!!data.errors.toProgram}
										inputRef={data.register({ required: true })}
										helperText={
											data.errors.toProgram ? 'Program is required.' : undefined
										}
									/>
								</Grid>

  <div style={{ height: "1rem" }}></div> {}
          <Grid container spacing={2}>
            {/* </Grid><Grid item xs={12}> */}
            <Grid item md={6} sm={6} xs={12}>
              {/* <FormControl component="fieldset"> */}
              <FormLabel component="legend">School Reporting Type</FormLabel>
              <RadioGroup
                name="schoolReportingType"
                row
                value={model.schoolReportingType}
                onChange={(_, v) => {
                  if (v === "ProgramReporter") {
                    model.startDate = undefined;
                  }
                  handleFieldOnChange("schoolReportingType", v);

                }}
              >
                {/* > */}
                <FormControlLabel
                  value="ProgramReporter"
                  control={<Radio color="primary" />}
                  label="Program Reporter"
                />
                <FormControlLabel
                  value="AcademicYearReporter"
                  control={<Radio color="primary" />}
                  label="Academic Year Reporter"
                />
              </RadioGroup>
            </Grid>

            {model.schoolReportingType === "AcademicYearReporter" && (
              <>
                <Grid item md={3} sm={3} xs={12}>
                  <FormLabel component="legend">
                    Control of Institutions
                  </FormLabel>

                  <Autocomplete
                    options={reportTypes}
                    getOptionLabel={(option) => option.text}
                    value={getSelectedItem(reportTypes, model.schoolType || "")}
                    onChange={(e: any, value: any) =>
                      handleFieldOnChange(
                        "schoolType",
                        value ? value.id : undefined
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="schoolType"
                        error={!!data.errors.schoolType}
                        inputRef={data.register({ required: true })}
                        helperText={
                          data.errors.schoolType
                            ? "Report Type is required."
                            : undefined
                        }
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {/* <Typography>
                Control of Institutions
              </Typography> */}
          <div style={{ height: "1rem" }}></div> {}
          {model.schoolReportingType === "AcademicYearReporter" && (
            <>        
              <div style={{ height: "1rem" }}></div> {}
              <h4>IMPORTANT MESSAGE:</h4>
              <Typography>
                Use the school&#39;s &#34;Official Report Date&#34; or October
                15th in the &#34;Report Date&#34; field below. The student
                counts on this report must tie into student counts on other
                IPEDS Surveys; otherwise, the U.S. Department of Education will
                flag these reports as inconsistent and will require further
                explanations.
              </Typography>
              <Typography>
                In addition, the &#34;Report End Date&#34; chosen for this
                report must be used for all other IPEDS reports that have this
                warning message. Please double-check your &#34;Report Date&#34;
                settings before submitting the data.
              </Typography>
              <div style={{ height: "1rem" }}></div> {}
              <Grid container spacing={1}>
                <Grid item md={3} sm={3} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      id="startDate"
                      name="startDate"
                      label="Date From *"
                      value={
                        reportingTypeA === "ProgramReporter"
                          ? null
                          : model.startDate || null
                      }
                      onChange={(date) => {
                        if (reportingTypeA !== "ProgramReporter") {
                          handleFieldOnChange("startDate", date);
                        } else {
                          handleFieldOnChange("startDate", null);
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </>
          )}
          {
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Report Date: {getDatesText(model.reportingYear || "")}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Autocomplete
                    renderInput={(inputProps) => {
                      return (
                        <InputField
                          name="reportingYear"
                          {...inputProps}
                          label="Cohort Year"
                          error={!!data.errors.reportingYear}
                          helperText={
                            data.errors.reportingYear &&
                            data.errors.reportingYear.message
                          }
                        />
                      );
                    }}
                    disableClearable
                    options={getReportingYearOptions()}
                    getOptionLabel={(option) => option}
                    value={model.reportingYear || null}
                    onChange={(_: any, v: string | null) => {
                      handleFieldOnChange("reportingYear", v);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Sort By</FormLabel>
                    <RadioGroup
                      name="orderBy"
                      row
                      value={model.orderBy}
                      onChange={(_, v) => {
                        handleFieldOnChange("orderBy", v);
                      }}
                    >
                      <FormControlLabel
                        value="StudentNumber"
                        control={<Radio color="primary" />}
                        label="Student Number"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="LastName"
                        control={<Radio color="primary" />}
                        label="Last Name"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                    <div
                      style={{ whiteSpace: "pre-wrap" }}
                      className={classes.errorText}
                    >
                    </div>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          }
        </form>
      </FormContext>
    </div>
  );

  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={() => {}}
      getReportParameters={() => model}
      exportHandler={onSubmit}
      previewLoader={false}
      preview={undefined}
      isUrlPreview
    />
  );
};

export default CostIIFinancialAidPartA;
