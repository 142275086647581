import { IIPedsReportParamsFallPartCALL } from "../../../interfaces/reports/IPedsReportParamsFallPartCALL";
import { IPEDSFall12MonthPartADetailReportParam } from "../../../interfaces/reports/ipeds/IPEDSFall12MonthPartADetailReportParam";
import { IPEDSFall12MonthPartASummaryReportParam } from "../../../interfaces/reports/ipeds/IPEDSFall12MonthPartASummaryReportParam";
import { IIPedsReportParamsFallPartASummary } from "../../../interfaces/reports/IPedsReportParamsFallPartASummary";
import { IIPedsReportParamsFallCIPData } from "../../../interfaces/reports/IPedsReportParamsFallCIPData";
import { IIPedsMissingDataReportParams } from "../../../interfaces/reports/IPedsMissingDataReportParams";
import { IIPedsReportParamsFallByLevel } from "../../../interfaces/reports/IPedsReportParamsFallByLevel";
import { ToBlob } from "../../../utils/base64Helper";
import API from "../../apiWrapper";
import { Download } from "../../../utils/file";
import { IFallAllCompletersDetailAndSummary } from "../../../interfaces/reports/IFallAllCompletersDetailAndSummary";

export const getIPEDSFallALLENRPartC = (
  params: IIPedsReportParamsFallPartCALL,
  isPreview: boolean = false
): Promise<any> => {
  const { campusId, progId, orderBy, reportingYear } = params;
  return API()
    .post("/Reports/IPEDS/FallAllInsPartC", {
      campusId,
      progId,
      orderBy,
      reportingYear,
    })
    .then(
      (response) => {
        if (isPreview) {
          return response.data.base64Data;
        }
        const extension = "pdf";
        var fileName =
          "IPEDSFallALLEnrPartC-" + params.reportingYear + "." + extension;

        Download(ToBlob(response.data.base64Data), fileName);
        return undefined;
      },
      (_) => {
        return null;
      }
    );
};

export const generateIPEDSFall12MonthPartADetailReport = (
  params: IPEDSFall12MonthPartADetailReportParam,
  isPreview: boolean = false
) => {
  return API()
    .post("/Reports/IPEDS/GetIPEDSFall12MonthPartADetail", params, {
      responseType: "blob",
    })
    .then(
      (response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Fall12MonthEnrollment_PartA_Detail_Report.pdf"
        );
        document.body.appendChild(link);
        link.click();
      },
      (error: any) => {
        return error;
      }
    );
};

export const generateIPEDSFall12MonthPartASummaryReport = (
  params: IPEDSFall12MonthPartASummaryReportParam,
  isPreview: boolean = false
) => {
  return API()
    .post("/Reports/IPEDS/GetIPEDSFall12MonthPartASummary", params, {
      responseType: "blob",
    })
    .then(
      (response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Fall12MonthEnrollment_PartA_Summary_Report.pdf"
        );
        document.body.appendChild(link);
        link.click();
      },
      (error: any) => {
        return error;
      }
    );
};

export const getFallAllCompletions_CIPData = (
  params: IIPedsReportParamsFallCIPData,
  isPreview: boolean = false
): Promise<any> => {
  const { campusId, progId, orderBy, reportingYear,showInactive  } = params;
  return API()
    .post("/Reports/IPEDS/FallAllCompletions_CIPData", {
      campusId,
      progId,
      orderBy,
      reportingYear,
      showInactive,
    })
    .then(
      (response) => {
        if (isPreview) {
          //return response.data;
          return response.data.base64Data;
        }
        const extension = "pdf";
        var fileName =
          "FallAllCompletions_CIPData-" +
          params.reportingYear +
          "." +
          extension;

        if (response.data.resultStatus == null) {
          Download(ToBlob(response.data.base64Data), fileName);
        }

        return response.data.resultStatus;
      },
      (_) => {
        return null;
      }
    );
};

export const getIPEDSMissingData = (
  params: IIPedsMissingDataReportParams,
  isPreview: boolean = false
): Promise<any> => {
  const {
    campusId,
    progId,
    offRepDate,
    excludeStuBefThisDate,
    orderBy,
    reportingYear,
  } = params;
  return API()
    .post("/Reports/IPEDS/IPEDSMissingData", {
      campusId,
      progId,
      offRepDate,
      excludeStuBefThisDate,
      orderBy,
      reportingYear,
    })
    .then(
      (response) => {
        if (isPreview) {
          //return response.data;
          return response.data.base64Data;
        }
        const extension = "pdf";
        var fileName =
          "IPEDSMissingData-" + params.reportingYear + "." + extension;

        Download(ToBlob(response.data.base64Data), fileName);
        //Added
        return response.data.resultStatus;
      },
      (_) => {
        return null;
      }
    );
};

export const getFallPartASummaryData = (
  params: IIPedsReportParamsFallPartASummary,
  isPreview: boolean = false
): Promise<any> => {
  const { campusId, progId, orderBy, reportingYear } = params;
  return API()
    .post("/Reports/IPEDS/FallPartASummaryData", {
      campusId,
      progId,
      orderBy,
      reportingYear,
    })
    .then(
      (response) => {
        if (isPreview) {
          return response.data.base64Data;
        }
        const extension = "pdf";
        var fileName =
          "FallPartASummaryData-" + params.reportingYear + "." + extension;

        Download(ToBlob(response.data.base64Data), fileName);
        return undefined;
      },
      (_) => {
        return null;
      }
    );
};

export const getFallAllCompletions_ByLevel = (
  params: IIPedsReportParamsFallByLevel,
  isPreview: boolean = false
): Promise<any> => {
  const { campusId, progId, orderBy, reportingYear,showInactive } = params;
  return API()
    .post("/Reports/IPEDS/FallAllCompletions_ByLevel", {
      campusId,
      progId,
      orderBy,
      reportingYear,
      showInactive
    })
    .then(
      (response) => {
        if (isPreview) {
          return response.data.base64Data;
        }
        const extension = "pdf";
        var fileName =
          "FallAllCompletions_ByLevel-" +
          params.reportingYear +
          "." +
          extension;
        if (response.data.resultStatus == null) {
          Download(ToBlob(response.data.base64Data), fileName);
        }
        return response.data.resultStatus;

        //return undefined;
      },
      (_) => {
        return null;
      }
    );
};


export const getCostIIFinancialAidPartA = (
  params: IIPedsReportParamsFallByLevel,
  isPreview: boolean = false
): Promise<any> => {
  const { campusId, progId, orderBy, reportingYear,showInactive, largestProgramID,schoolReportingType, 
    startDate,
    schoolType

   } = params;

  console.log("Hit1")
  return API()
    .post("/Reports/IPEDS/CostIIFinancialAidPartA", {
      campusId,
      progId,
      orderBy,
      reportingYear,
      showInactive,
      largestProgramID,
      schoolReportingType,
      startDate,
      schoolType
  
    })
    .then(
      (response) => {
        if (isPreview) {
          return response.data.base64Data;
        }
        const extension = "pdf";
        var fileName =
          "CostIIFinancialAidPartA" +
          params.reportingYear +
          "." +
          extension;
        //if (response.data.resultStatus == null) {
          Download(ToBlob(response.data.base64Data), fileName);
        //}
        return response.data.resultStatus;
        //return undefined;
      },
      (_) => {
        return null;
      }
    );
};

export const getFallAllCompletersDetailAndSummary = (
  params: IFallAllCompletersDetailAndSummary,
  isPreview: boolean = false
): Promise<any> => {
  const {
    campusId,
    progId,
    startDate,
    endDate,
    dateRangeText,
    orderBy,
    selectedReportYear,
  } = params;
  return API()
    .post("/Reports/IPEDS/FallAllCompletersDetailAndSummary", {
      campusId,
      progId,
      startDate,
      endDate,
      dateRangeText,
      orderBy,
      selectedReportYear,
    })
    .then(
      (response) => {
        if (isPreview) {
          return response.data.base64Data;
        }
        const extension = "pdf";
        var fileName =
          "IPEDSFallALLCompleters-" +
          params.selectedReportYear +
          "." +
          extension;

        Download(ToBlob(response.data.base64Data), fileName);
        return undefined;
      },
      (_) => {
        return null;
      }
    );
};

export const getFall12MonthEnrollmentPartBDetailAndSummary = (
  params: IFallAllCompletersDetailAndSummary,
  isPreview: boolean = false
): Promise<any> => {
  const {
    campusId,
    progId,
    startDate,
    endDate,
    dateRangeText,
    orderBy,
    selectedReportYear,
  } = params;
  return API()
    .post("/Reports/IPEDS/Fall12MonthEnrollmentPartBDetailAndSummary", {
      campusId,
      progId,
      startDate,
      endDate,
      dateRangeText,
      orderBy,
      selectedReportYear,
    })
    .then(
      (response) => {
        if (isPreview) {
          return response.data.base64Data;
        }
        const extension = "pdf";
        var fileName =
          "IPEDSFall12MonthEnrollmentPartB-" +
          params.selectedReportYear +
          "." +
          extension;

        Download(ToBlob(response.data.base64Data), fileName);
        return undefined;
      },
      (_) => {
        return null;
      }
    );
};
